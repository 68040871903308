@import url('https://code.ionicframework.com/ionicons/2.0.1/css/ionicons.min.css');
@import url('https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.3.0/css/flag-icon.min.css');
@import url('https://fonts.googleapis.com/css?family=Roboto:300,400,500,600,700');

@import 'bootstrap-daterangepicker/daterangepicker.css';
@import '~@fortawesome/fontawesome-free/css/all';
@import '~icheck-bootstrap/icheck-bootstrap';
@import '~admin-lte/build/scss/adminlte';
@import '~react-toastify/scss/main';

body {
  margin: 0;
  font-family: Roboto, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #eee;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

// Fix select styles, for some reason original bootstrap select has wrong padding
select {
  background-color: #fff;
  background-image: url("data:image/svg+xml,%3Csvg aria-hidden='true' focusable='false' data-prefix='fas' data-icon='chevron-down' class='svg-inline--fa fa-chevron-down fa-w-14' role='img' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 448 512'%3E%3Cpath fill='currentColor' d='M207.029 381.476L12.686 187.132c-9.373-9.373-9.373-24.569 0-33.941l22.667-22.667c9.357-9.357 24.522-9.375 33.901-.04L224 284.505l154.745-154.021c9.379-9.335 24.544-9.317 33.901.04l22.667 22.667c9.373 9.373 9.373 24.569 0 33.941L240.971 381.476c-9.373 9.372-24.569 9.372-33.942 0z'%3E%3C/path%3E%3C/svg%3E");
  background-size: 12px;
  background-position: right 0.75rem center;
  background-repeat: no-repeat;
  appearance: none;
  &.form-control {
    padding-right: 1.7rem; // fix overlap of text and icon
  }
}

// Fix popover size, by default bootstrap defined it too small
.popover {
  max-width: 600px !important;
  width: auto;
}

.tooltip {
  z-index: 9999; // fix tooltip display is side drawer
}

.modal-backdrop.show {
  opacity: 0.3;
}

// Override custom checkbox styles
.custom-control-label::before {
  background-color: #fff;
  box-shadow: none;
}

// Override date picker z-index
.react-datepicker__tab-loop,
.react-datepicker-popper {
  z-index: 100 !important;
}

.bg-gray-light-2 {
  background-color: #b9c0c3;
  color: #1f2d3d !important;
}

div.content-wrapper {
  min-height: calc(100vh - 57px); // set initial height as adminLTE plugin not working correctly, 57 is header height
}

.sidebar {
  overflow-y: auto; // fix sidebar scroll issue
}
